import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

const urlBase = `${api}`;

export default {
    obterRelatorio(inicio, fim) {
        return axiosJwt.get(`${urlBase}/solucoes/relatorio?inicio=${inicio}&fim=${fim}`);
    },
    gerarRelatorioPlanilhaExcel(inicio, fim) {
        return axiosJwt.get(`${urlBase}/solucoes/planilhasolucao?inicio=${inicio}&fim=${fim}`, { responseType: 'blob' });
    },
};
