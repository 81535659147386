<template>
    <painel titulo="Relatório de Soluções">
        <div class="grid p-fluid mt-2 ml-2">
            <div class="field col-12 md:col-3 lg:col-2">
                <label class="required" for="inicio">Inicio</label>
                <Calendar v-model="inicio" dateFormat="dd/mm/yy" itemid="inicio" />
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <label class="required" for="fim">Fim</label>
                <Calendar v-model="fim" dateFormat="dd/mm/yy" itemid="fim" />
            </div>
            <div class="flex align-items-center field mt-4">
                <Button @click="obterTodos" :disabled="!inicio || !fim" icon="pi pi-search"></Button>
            </div>
        </div>
        <div class="container text-center mt-5 mb-5">
            <div class="table-responsive my-5">
                <tabela
                    headerStyle="width: 3em"
                    :mostrarPesquisa="true"
                    id="listagemVitrine"
                    :salvarEstado="false"
                    :mostrarExportarXlsx="true"
                    :gerarPlanilhaXlsx="gerarPlanilhaXlsx"
                    :data="data"
                    v-if="data.length > 0"
                >
                    <template #conteudo>
                        <Column headerStyle="width: 100px">
                            <template #body="slotProps">
                                <btn-visualizar title="Visualizar" @onVisualizar="visualizar" :objeto="slotProps.data"></btn-visualizar>
                            </template>
                        </Column>
                        <template v-for="(campo, index) in campos" :key="index">
                            <Column :header="campo.titulo" :field="campo.campo" :sortable="true" v-if="!campo.campoActive"> </Column>
                            <Column :header="campo.titulo" v-if="campo.campoActive" :field="campo.campoActive" :sortable="true">
                                <template #body="slotProps">
                                    <status :status="slotProps.data.active"></status>
                                </template>
                            </Column>
                        </template>
                    </template>
                </tabela>
            </div>
        </div>
    </painel>
    <router-view></router-view>
</template>
<script>
import Services from './service';
import BtnVisualizar from '../components/BtnVisualizar.vue';

export default {
    name: 'Sol',
    components: {
        BtnVisualizar,
    },
    data() {
        return {
            idTabela: 'Relatorio',
            inicio: null,
            fim: null,
            inativos: true,
            data: [],
            campos: [
                { campo: 'codSolucaoSas', titulo: 'Código SAS' },
                { campo: 'title', titulo: 'Título' },
                { campo: 'tipoServico.title', titulo: 'Intrumento' },
                { campo: 'origemContratacao.title', titulo: 'Origem de Contratação' },
                { campo: 'created', titulo: 'Criado' },
                { campo: 'modified', titulo: 'Modificado' },
                { campo: 'modifiedBy', titulo: 'Modificado por' },
                { campo: 'motivoDescricao', titulo: 'Motivo' },
                { campoActive: 'active', titulo: 'Status' },
            ],
        };
    },
    methods: {
        obterTodos() {
            this.$store.dispatch('addRequest');
            let inicioFmt = this.$moment(this.inicio).format('YYYY-MM-DDT00:00');
            let fimFmt = this.$moment(this.fim).format('YYYY-MM-DDT23:59');
            let dataAtual = this.$moment().format('YYYY-MM-DDT23:59');

            if (inicioFmt > fimFmt) {
                this.$store.dispatch('removeRequest');
                this.mesnsagemErro('Data inicial não pode ser maior que a data final');
                return;
            } else if (inicioFmt > dataAtual) {
                this.$store.dispatch('removeRequest');
                this.mesnsagemErro('Data inicial não pode ser maior que a data atual');
                return;
            } else if (fimFmt > dataAtual) {
                this.$store.dispatch('removeRequest');
                this.mesnsagemErro('Data final não pode ser maior que a data atual');
                return;
            } else {
                Services.obterRelatorio(inicioFmt, fimFmt).then((response) => {
                    if (response?.success) {
                        this.data = response.data;
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
        },

        mesnsagemErro(mensagem) {
            this.$toast.add({
                severity: 'error',
                summary: 'Erro',
                detail: mensagem,
            });
        },

        visualizar(objeto) {
            this.$router.push({
                name: `Solucao_Visualizar`,
                params: {
                    id: objeto.id,
                },
                query: {
                    view: 'relatorio',
                },
            });
        },

        gerarPlanilhaXlsx() {
            this.$store.dispatch('addRequest');
            let inicioFmt = this.$moment(this.inicio).format('YYYY-MM-DDT00:00');
            let fimFmt = this.$moment(this.fim).format('YYYY-MM-DDT23:59');
            let dataAtual = this.$moment().format('YYYY-MM-DDT23:59');

            if (inicioFmt > fimFmt) {
                this.$store.dispatch('removeRequest');
                this.mesnsagemErro('Data inicial não pode ser maior que a data final');
                return;
            } else if (inicioFmt > dataAtual) {
                this.$store.dispatch('removeRequest');
                this.mesnsagemErro('Data inicial não pode ser maior que a data atual');
                return;
            } else if (fimFmt > dataAtual) {
                this.$store.dispatch('removeRequest');
                this.mesnsagemErro('Data final não pode ser maior que a data atual');
                return;
            } else {
                Services.gerarRelatorioPlanilhaExcel(inicioFmt, fimFmt).then((response) => {
                    if (response?.success) {
                        this.$download(
                            response.data,
                            `Relatorio_Solucoes_${this.$moment(this.inicio).format('DD-MM-YYYY')}_${this.$moment(this.fim).format('DD-MM-YYYY')}.xls`
                        );
                    } else {
                        this.mesnsagemErro('Erro ao gerar planilha');
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
        },
    },
};
</script>
